<template>
    <section class="all_rating_list">
        <h2 class="main_heading">
            <button type="button" class="btn back_btn" tapable @click="$router.go(-1)">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button> Rating
        </h2>
        <div class="container-fluid pt-5">
            <!-- <div class="row">
                <div class="col-sm-3">
                    <div class="rating-block">
                        <h4>Average user rating</h4>
                        <h2 class="bold padding-bottom-7">4.3 <small>/ 5</small></h2>
                        <button type="button" class="btn btn-warning btn-sm" aria-label="Left Align">
                            <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-warning btn-sm" aria-label="Left Align">
                            <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-warning btn-sm" aria-label="Left Align">
                            <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-default btn-grey btn-sm" aria-label="Left Align">
                            <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-default btn-grey btn-sm" aria-label="Left Align">
                            <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div class="col-sm-3">
                    <h4>Rating breakdown</h4>
                    <div class="pull-left">
                        <div class="pull-left" style="width:35px; line-height:1;">
                            <div style="height:9px; margin:5px 0;">5 <span class="glyphicon glyphicon-star"></span>
                            </div>
                        </div>
                        <div class="pull-left" style="width:180px;">
                            <div class="progress" style="height:9px; margin:8px 0;">
                                <div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="5"
                                    aria-valuemin="0" aria-valuemax="5" style="width: 1000%">
                                    <span class="sr-only">80% Complete (danger)</span>
                                </div>
                            </div>
                        </div>
                        <div class="pull-right" style="margin-left:10px;">1</div>
                    </div>
                    <div class="pull-left">
                        <div class="pull-left" style="width:35px; line-height:1;">
                            <div style="height:9px; margin:5px 0;">4 <span class="glyphicon glyphicon-star"></span>
                            </div>
                        </div>
                        <div class="pull-left" style="width:180px;">
                            <div class="progress" style="height:9px; margin:8px 0;">
                                <div class="progress-bar progress-bar-primary" role="progressbar" aria-valuenow="4"
                                    aria-valuemin="0" aria-valuemax="5" style="width: 80%">
                                    <span class="sr-only">80% Complete (danger)</span>
                                </div>
                            </div>
                        </div>
                        <div class="pull-right" style="margin-left:10px;">1</div>
                    </div>
                    <div class="pull-left">
                        <div class="pull-left" style="width:35px; line-height:1;">
                            <div style="height:9px; margin:5px 0;">3 <span class="glyphicon glyphicon-star"></span>
                            </div>
                        </div>
                        <div class="pull-left" style="width:180px;">
                            <div class="progress" style="height:9px; margin:8px 0;">
                                <div class="progress-bar progress-bar-info" role="progressbar" aria-valuenow="3"
                                    aria-valuemin="0" aria-valuemax="5" style="width: 60%">
                                    <span class="sr-only">80% Complete (danger)</span>
                                </div>
                            </div>
                        </div>
                        <div class="pull-right" style="margin-left:10px;">0</div>
                    </div>
                    <div class="pull-left">
                        <div class="pull-left" style="width:35px; line-height:1;">
                            <div style="height:9px; margin:5px 0;">2 <span class="glyphicon glyphicon-star"></span>
                            </div>
                        </div>
                        <div class="pull-left" style="width:180px;">
                            <div class="progress" style="height:9px; margin:8px 0;">
                                <div class="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="2"
                                    aria-valuemin="0" aria-valuemax="5" style="width: 40%">
                                    <span class="sr-only">80% Complete (danger)</span>
                                </div>
                            </div>
                        </div>
                        <div class="pull-right" style="margin-left:10px;">0</div>
                    </div>
                    <div class="pull-left">
                        <div class="pull-left" style="width:35px; line-height:1;">
                            <div style="height:9px; margin:5px 0;">1 <span class="glyphicon glyphicon-star"></span>
                            </div>
                        </div>
                        <div class="pull-left" style="width:180px;">
                            <div class="progress" style="height:9px; margin:8px 0;">
                                <div class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="1"
                                    aria-valuemin="0" aria-valuemax="5" style="width: 20%">
                                    <span class="sr-only">80% Complete (danger)</span>
                                </div>
                            </div>
                        </div>
                        <div class="pull-right" style="margin-left:10px;">0</div>
                    </div>
                </div>
            </div> -->
            <div class="review-block">
                <div class="review_inner_box">
                    <div class="row align-items-center">
                        <div class="col-auto text-center">
                            <div class="dctr_prfl_img">
                                <template v-if="doctorInfo && doctorInfo.profile.thumbnail">
                                <div class="prfl_avatar_lazy_load_box">
                                    <img :src="doctorInfo.profile.thumbnail" alt="" class="img-fluid">
                                    <img  v-if="doctorInfo.profile.avatar" :src="doctorInfo.profile.avatar" alt="" class="img-fluid">
                                </div>
                                </template>
                                <img src="@/assets/images/user_default.png" alt="" class="img-fluid" v-else>
                                <i class="online-status"></i>
                            </div>
                            <div class="review-block-name"><a href="#">Prince Tiwari</a></div>
                        </div>
                        <div class="col">
                            <div class="review-block-rate">
                                <star-rating :star-size="30" :star-type="'fa fa-star'" :increment="0.5" v-model="currentRating" :read-only="true"/>
                            </div>
                            <div class="review-block-title">this was nice in buy</div>
                            <div class="review-block-date">January 29, 2024</div>
                        </div>
                    </div>
                </div>
                <div class="review_inner_box">
                    <div class="row align-items-center">
                        <div class="col-auto text-center">
                            <div class="dctr_prfl_img">
                                <template v-if="doctorInfo && doctorInfo.profile.thumbnail">
                                <div class="prfl_avatar_lazy_load_box">
                                    <img :src="doctorInfo.profile.thumbnail" alt="" class="img-fluid">
                                    <img  v-if="doctorInfo.profile.avatar" :src="doctorInfo.profile.avatar" alt="" class="img-fluid">
                                </div>
                                </template>
                                <img src="@/assets/images/user_default.png" alt="" class="img-fluid" v-else>
                                <i class="online-status"></i>
                            </div>
                            <div class="review-block-name"><a href="#">Prince Tiwari</a></div>
                        </div>
                        <div class="col">
                            <div class="review-block-rate">
                                <star-rating :star-size="30" :star-type="'fa fa-star'" :increment="0.5" v-model="currentRating" :read-only="true"/>
                            </div>
                            <div class="review-block-title">this was nice in buy</div>
                            <div class="review-block-date">January 29, 2024</div>
                        </div>
                    </div>
                </div>
                <div class="review_inner_box">
                    <div class="row align-items-center">
                        <div class="col-auto text-center">
                            <div class="dctr_prfl_img">
                                <template v-if="doctorInfo && doctorInfo.profile.thumbnail">
                                <div class="prfl_avatar_lazy_load_box">
                                    <img :src="doctorInfo.profile.thumbnail" alt="" class="img-fluid">
                                    <img  v-if="doctorInfo.profile.avatar" :src="doctorInfo.profile.avatar" alt="" class="img-fluid">
                                </div>
                                </template>
                                <img src="@/assets/images/user_default.png" alt="" class="img-fluid" v-else>
                                <i class="online-status"></i>
                            </div>
                            <div class="review-block-name"><a href="#">Prince Tiwari</a></div>
                        </div>
                        <div class="col">
                            <div class="review-block-rate">
                                <star-rating :star-size="30" :star-type="'fa fa-star'" :increment="0.5" v-model="currentRating" :read-only="true"/>
                            </div>
                            <div class="review-block-title">this was nice in buy</div>
                            <div class="review-block-date">January 29, 2024</div>
                        </div>
                    </div>
                </div>
                <div class="review_inner_box">
                    <div class="row align-items-center">
                        <div class="col-auto text-center">
                            <div class="dctr_prfl_img">
                                <template v-if="doctorInfo && doctorInfo.profile.thumbnail">
                                <div class="prfl_avatar_lazy_load_box">
                                    <img :src="doctorInfo.profile.thumbnail" alt="" class="img-fluid">
                                    <img  v-if="doctorInfo.profile.avatar" :src="doctorInfo.profile.avatar" alt="" class="img-fluid">
                                </div>
                                </template>
                                <img src="@/assets/images/user_default.png" alt="" class="img-fluid" v-else>
                                <i class="online-status"></i>
                            </div>
                            <div class="review-block-name"><a href="#">Prince Tiwari</a></div>
                        </div>
                        <div class="col">
                            <div class="review-block-rate">
                                <star-rating :star-size="30" :star-type="'fa fa-star'" :increment="0.5" v-model="currentRating" :read-only="true"/>
                            </div>
                            <div class="review-block-title">this was nice in buy</div>
                            <div class="review-block-date">January 29, 2024</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- /container -->
    </section>
</template>


<script>
/* eslint-disable */
import StarRating from 'vue-star-rating';
export default {
    props: [],
    components: {
        StarRating, // Register the component
    },
    data() {
        return {
            currentRating:3.5,
            doctorInfo:null
        };
    },
    mounted(){
    },
    methods: {
    }
}
</script>


<style lang="scss" scoped>
.main_heading{color:#ffffff;font-size:24px;text-transform:uppercase;font-weight:bold;margin:0px;}
.review-block {
    background-color: rgba(163, 209, 233, 0.1);
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.review-block-name {
    font-size: 18px;
    margin: 5px 0 0;
    a{color:#87cefa;}
}

.review-block-date {
    font-size: 16px;
    line-height: 1;
    color:#a560e8;
}

.review-block-rate {
    font-size: 14px;
    margin-bottom: 10px;
}

.review-block-title {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 1.3;
}
.review_inner_box {
    background: rgba(42, 42, 45, 0.3);
    padding: 15px;
    margin-bottom: 3px;
    border-radius: 5px;
    color: #fff;
    font-family:'MyriadProRegular', 'Lato', sans-serif;
    .vue-star-rating-star {
        display: inline-block;
        width: 22px;
        height: 22px;
    }
}
        .dctr_prfl_img{
           & > .img-fluid{
                width: 120px;
                height: 120px;
                display: inline-block;
                border: 2px solid #efefef;
                border-radius: 50%;
            }
            .prfl_avatar_lazy_load_box {
                width: 120px;
                height: 120px;
                border-radius: 50%;
                border: 2px solid #efefef;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                overflow:hidden;
            }
        }
</style>